import React, { useState } from 'react';
import ProductList from './ProductList';
import CategoryFilter from './CategoryFilter';

const FilterButton: React.FC<{ emoji: string; title: string }> = ({ emoji, title }) => (
  <button className="flex items-center space-x-2 bg-white rounded-full px-4 py-2 shadow-md hover:shadow-lg transition-shadow">
    <span>{emoji}</span>
    <span>{title}</span>
  </button>
);

const HomePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  return (
    <div className="min-h-screen flex flex-col">
      <div 
        className="h-[60vh] bg-cover bg-center relative"
        style={{ backgroundImage: "url('/background.png')" }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center justify-center">
          <img src="/logo.png" alt="Buy It For Life Logo" className="w-64 mb-4" />
          <h2 className="text-3xl text-[#FDFFFC] font-serif text-center mb-8">
            We've scoured <span className="text-[#FF7D00] font-bold">r/BuyItForLife</span> and the web for the best lifetime products<br />
            <b>so you don't have to.</b>
          </h2>
          <div className="flex justify-center mb-8">
            <input
              type="text"
              placeholder="Find products for life..."
              className="p-4 border rounded-full w-96 text-lg font-bold placeholder-gray-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 100L60 91.7C120 83.3 240 66.7 360 58.3C480 50 600 50 720 58.3C840 66.7 960 83.3 1080 87.5C1200 91.7 1320 83.3 1380 79.2L1440 75V100H1380C1320 100 1200 100 1080 100C960 100 840 100 720 100C600 100 480 100 360 100C240 100 120 100 60 100H0Z" fill="#FDFFFC"/>
        </svg>
      </div>
      <div className="flex-grow bg-[#FDFFFC]">
        <div className="container mx-auto px-4 py-8">
          <div className="flex justify-center space-x-4 mb-8">
            <CategoryFilter
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <FilterButton emoji="⏳" title="Longevity" />
            <FilterButton emoji="👥" title="Community Recommendation" />
            <FilterButton emoji="🔥" title="Hot" />
            <FilterButton emoji="✨" title="New" />
            
          </div>
          <ProductList searchTerm={searchTerm} selectedCategory={selectedCategory} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;