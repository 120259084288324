import React, { useState } from 'react';

const categories = [
  { name: 'All', icon: '🌐' },
  { name: 'Kitchen', icon: '🍳' },
  { name: 'Footwear', icon: '👟' },
  { name: 'Other', icon: '📦' },
];

interface CategoryFilterProps {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({ selectedCategory, setSelectedCategory }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className={`flex items-center space-x-2 bg-white rounded-full px-4 py-2 shadow-md hover:shadow-lg transition-shadow ${isOpen ? 'border-2 border-blue-300' : ''}`}
      >
        <span>{categories.find(c => c.name === selectedCategory)?.icon || '🔍'}</span>
        <span>Category: {selectedCategory}</span>
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-2 w-64 bg-white rounded-md shadow-lg z-10 p-2">
          {categories.map((category) => (
            <button
              key={category.name}
              className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-100 rounded-md"
              onClick={() => {
                setSelectedCategory(category.name);
                setIsOpen(false);
              }}
            >
              <span className="mr-2">{category.icon}</span>
              {category.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;