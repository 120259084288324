import React from 'react';
import { Product } from '../productApi';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const extractCategory = (description: string | null | undefined): string => {
    if (!description) return 'Uncategorized';
    const match = description.match(/Category:\s*([^\n]+)/);
    return match ? match[1] : 'Uncategorized';
  };

  const category = extractCategory(product.description);

  console.log('Product:', product);
  console.log('Product images:', product.images);

  if (product.images && product.images.length > 0) {
    console.log('First image data (first 100 chars):', product.images[0].imageData.slice(0, 100));
  } else {
    console.log('No images available for this product');
  }

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error('Image failed to load:', e);
    (e.target as HTMLImageElement).src = '/placeholder-image.jpg';
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      {product.images && product.images.length > 0 ? (
        <img
          src={`data:image/png;base64,${product.images[0].imageData}`}
          alt={product.name || 'Product image'}
          className="w-full h-48 object-cover"
          onError={handleImageError}
        />
      ) : (
        <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
          <span className="text-gray-500">No image available</span>
        </div>
      )}
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2">{product.name || 'Unnamed Product'}</h2>
        <p className="text-sm text-gray-600 mb-2">{product.description || 'No description available'}</p>
        <p className="font-bold text-lg mb-2">${product.price || 'N/A'}</p>
        <p className="text-sm text-gray-500">Category: {category}</p>
      </div>
    </div>
  );
};

export default ProductCard;