import axios from 'axios';

export interface ProductImage {
  id: string;
  imageName: string;
  imageData: string; // Base64 encoded image data
}

export interface Product {
  id: string;
  name: string | null;
  description: string | null;
  price: string | null;
  source_post_id: string | null;
  images: ProductImage[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export const getProductImageUrl = (productId: string, imageId: string): string => {
  return `${API_BASE_URL}/products/${productId}/images/${imageId}`;
};

export const fetchProducts = async (): Promise<Product[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}/products`);
    if (!response.ok) {
      throw new Error(`Failed to fetch products: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    console.log('Received product data:', data);
    const products = data.map((product: Product) => ({
      ...product,
      images: product.images && Array.isArray(product.images) ? product.images : []
    }));
    console.log('Processed products:', products);
    // Log the first product's image data if available
    if (products.length > 0 && products[0].images && products[0].images.length > 0) {
      console.log('First product, first image data (first 100 chars):',
        products[0].images[0].imageData.slice(0, 100));
    }
    return products;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

export const uploadProductImage = async (productId: string, imageName: string, imageFile: File): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('imageName', imageName);
    await axios.post(`${API_BASE_URL}/products/${productId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error uploading product image:', error);
    throw error;
  }
};

export const extractCategory = (description: string | null | undefined): string => {
  if (!description) return 'Uncategorized';
  const match = description.match(/Category:\s*([^\n]+)/);
  return match ? match[1] : 'Uncategorized';
}; 