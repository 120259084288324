import React, { useState, useEffect } from 'react';
import { fetchProducts, Product } from '../productApi';
import ProductCard from './ProductCard';

const extractCategory = (description: string | null | undefined): string => {
  if (!description) return 'Uncategorized';
  const match = description.match(/Category:\s*([^\n]+)/);
  return match ? match[1] : 'Uncategorized';
};

interface ProductListProps {
  searchTerm: string;
  selectedCategory: string;
}

const ProductList: React.FC<ProductListProps> = ({ searchTerm, selectedCategory }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    const loadProducts = async () => {
      const fetchedProducts = await fetchProducts();
      console.log('Fetched products:', fetchedProducts);
      setProducts(fetchedProducts);
    };
    loadProducts();
  }, []);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const searchMatch = (product.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product.description || '').toLowerCase().includes(searchTerm.toLowerCase());
      const categoryMatch = selectedCategory === 'All' || extractCategory(product.description) === selectedCategory;
      return searchMatch && categoryMatch;
    });
    console.log('Filtered products:', filtered);
    setFilteredProducts(filtered);
  }, [products, searchTerm, selectedCategory]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {filteredProducts.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;