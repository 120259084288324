import React from 'react';
import { Link } from 'react-router-dom';

const AffiliateDisclosure: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Link 
        to="/" 
        className="inline-block mb-6 px-4 py-2 bg-[#1F2041] text-[#FDFFFC] rounded-full hover:bg-opacity-90 transition-colors"
      >
        ← Back to Home
      </Link>
      
      <h1 className="text-3xl font-bold mb-4">Affiliate Disclosure</h1>
      
      <p className="mb-4">
        Buy It For Life (BIFL) is reader-supported. When you buy through links on our site, we may earn an affiliate commission at no additional cost to you.
      </p>
      
      <p className="mb-4">
        We participate in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites. In the future, we may expand to include other affiliate programs.
      </p>
      
      <p className="mb-4">
        It's important to note that our participation in affiliate programs does not influence our product ratings, recommendations, or the content we produce. We strive to provide honest, unbiased information to help you make informed decisions about long-lasting products.
      </p>
      
      <p className="mb-4">
        While we use affiliate links for some, perhaps most, products on our site, not all links are affiliate links. We always aim to be transparent about our affiliations and welcome any questions you might have about our content or affiliate relationships.
      </p>
      
      <p>
        Thank you for supporting BIFL. Your trust is important to us, and we're committed to maintaining transparency in all our operations.
      </p>
    </div>
  );
};

export default AffiliateDisclosure;