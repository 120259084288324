import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Link
        to="/"
        className="inline-block mb-6 px-4 py-2 bg-[#1F2041] text-[#FDFFFC] rounded-full hover:bg-opacity-90 transition-colors"
      >
        ← Back to Home
      </Link>
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p>
          At Buy It For Life (BIFL), we are committed to protecting your privacy. This Privacy Policy explains our practices regarding the collection, use, and disclosure of information on our website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">2. Information Collection</h2>
        <p>
          We do not collect any personal information from our users. Our website is designed to provide information about durable products without requiring any user data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">3. Cookies and Tracking Technologies</h2>
        <p>
          We do not use cookies or any other tracking technologies on our website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Third-Party Services</h2>
        <p>
          We do not use any third-party services that might collect user data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">5. Data Protection</h2>
        <p>
          As we do not collect any user data, there is no risk of data breaches or unauthorized access to personal information through our website.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Children's Privacy</h2>
        <p>
          Our website is intended for a general audience and does not knowingly collect any personal information from children.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">7. International Users</h2>
        <p>
          Our website is intended for a global audience. By using this site, you agree to this Privacy Policy regardless of your location.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. GDPR Compliance</h2>
        <p>
          Our practices are in line with the General Data Protection Regulation (GDPR) as we do not collect or process any personal data.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">9. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify users of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the bottom of this page.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">10. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at martin@generativeai.net.
        </p>
      </section>

      <p className="mt-8 text-sm text-gray-600">
        Last updated: 20.09.2024
      </p>
    </div>
  );
};

export default PrivacyPolicy;